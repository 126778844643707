
.top-right{
    .addBtn{
    }
}
.bulletin-box{
    display: flex;
    flex-direction: column;
    .bulletin-title,.bulletin-content{
        display: flex;
        flex-direction: column;
        font-size: 14px;
        color: #333333;
        span{
            line-height: 20px;
            &:nth-child(1){
                color: #999999;
            }
            &:nth-child(2){
                margin-top: 8px;
            }
        }
    }
    .bulletin-content{
        margin-top: 14px;
    }
}
